<template>
  <div class="admin-incident-list-item" @click.prevent="$emit('selected')">
    <div class="left">
      <incident-thumbnail
        size="large"
        :incident-id="incident.id"
        :incident-owner-id="incident.ownerId"
        :created-by="incident.createdBy"
        :tenant-id="tenantId"
      ></incident-thumbnail>
    </div>
    <div class="right">
      <div class="name">
        {{ displayName }}
      </div>
      <incident-tags :incident="incident"></incident-tags>
      {{ getLastActivityHuman(incident) }}
    </div>
    <div class="super-right" v-if="!hidePin">
      <pin-button :guid="incident.id"></pin-button>
    </div>
  </div>
</template>

<script>
import IncidentThumbnail from "../../../components/Incidents/IncidentThumbnail.vue";
import IncidentTags from "../../../components/Incidents/IncidentTags.vue";
import PinButton from "../../../components/PinButton.vue";
import Dates from "../../../mixins/Dates";
export default {
  components: { IncidentThumbnail, IncidentTags, PinButton },
  props: ["incident", "tenantId", "hidePin"],
  mixins: [Dates],
  computed: {
    displayName() {
      if (this.incident.displayName && this.incident.displayName.length) {
        return this.incident.displayName;
      }
      return "Unknown";
    },
  },
};
</script>

<style scoped lang="scss">
.admin-incident-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .super-right {
    padding-left: 5px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>