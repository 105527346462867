<template>
  <div class="admin-reporting-list-item" @click.prevent="$emit('selected')">
    <div class="left">
      <presence-thumbnail
        :ownerId="report['ownerId']"
        size="large"
        :presenceId="report['createdBy']"
      ></presence-thumbnail>
    </div>
    <div class="middle" :key="timeAgoRefreshKey">
      <div class="name">
        {{ reportTitle }}
      </div>
      {{ displayTimeAgo(report["whenCreated"]) }}
    </div>
    <div class="right">
      <a-badge v-if="!whenProcessed" count="Processing"></a-badge>
      <a-icon :style="{'fontSize': '19px'}" v-else type="cloud-download" />
    </div>
  </div>
</template>

<script>
import PresenceThumbnail from '../../../components/Presences/PresenceThumbnail.vue';
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
import reporting from "../../../api/reporting";
export default {
  components: { PresenceThumbnail },
  props: ["tenantId", "report"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      whenProcessed: null,
      updateAttempts: 0
    }
  },
  created() {
    this.whenProcessed = this.report['whenProcessed'];
    if (!this.whenProcessed) {
      this.updateWhenProcessed();
    }
  },
  computed: {
      reportTitle() {
          let t = this.report['criteria']['$t'];
          if (t == 'Soteria.Models.ReportCriteriaOrgGuidanceViewed, Soteria.Models') {
              return 'Guidance Pack Heat Map';
          }
          if (t == 'Soteria.Models.ReportCriteriaOrgIncidentSummary, Soteria.Models') {
            return 'Incident Summary Report';
          }
          return 'Unknown';
      }
  },
  methods: {
    updateWhenProcessed() {
      let vm = this;
      if (
        !vm.whenProcessed
        && vm.updateAttempts < 10
      ) {

        reporting.getReport(this.tenantId, this.report.id).then(r => {
          vm.updateAttempts = vm.updateAttempts + 1;
          let newReport = r.data;
          if (newReport['whenProcessed']) {
            vm.whenProcessed = newReport['whenProcessed'];
          } else {
            window.setTimeout(vm.updateWhenProcessed, 1000);
          }
        }).catch(e => {
          console.log(e);
        });

      }
    }
  }
};
</script>

<style scoped lang="scss">
.admin-reporting-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .middle {
    padding-left: 15px;
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>